import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { onImageError } from '@/utils/sentry/onImageError';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
import { Button } from '../Button/Button';
const useSticky = () => {
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const [sticky, setSticky] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (!stickyRef.current) {
      return;
    }
    setOffset(stickyRef.current.offsetTop);
  }, [stickyRef, setOffset]);
  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) {
        return;
      }
      setSticky(window.scrollY > offset);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setSticky, stickyRef, offset]);
  return {
    stickyRef,
    sticky
  };
};
type NavigationTab = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  active?: boolean;
};
type TopNavLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type SubnavLink = {
  text?: string;
  subtext?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type SubnavGroup = {
  heading: string;
  subnavLinks: SubnavLink[];
};
type MainNavLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  subnavGroups: SubnavGroup[];
};
type Props = {
  className?: string;
  navigationTabs: NavigationTab[];
  topNavLinks?: TopNavLink[];
  logoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  mobileLogoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  logoWidth: number;
  mobileLogoWidth: number;
  logoAlt: string;
  logoLink?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  mainNavLinks?: MainNavLink[];
  ctaLink?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  ctaText?: string;
};
function getFlagEmoji(locale: Intl.Locale): string | undefined {
  const region = locale.region;
  if (region == null) return;
  const codePoints = region.toUpperCase().split('').map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
export function Navigation({
  className,
  navigationTabs,
  topNavLinks,
  logoImage,
  mobileLogoImage,
  logoAlt,
  logoWidth,
  mobileLogoWidth,
  logoLink,
  mainNavLinks,
  ctaText,
  ctaLink
}: Props) {
  const {
    sticky,
    stickyRef
  } = useSticky();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', mobileNavOpen);
  }, [mobileNavOpen]);
  const {
    locale,
    defaultLocale,
    locales,
    domainLocales
  } = useRouter();
  const currentLocale = locale ?? defaultLocale;
  const countries = useMemo(() => {
    if (locales == null) return [];
    const omitLocales = ['en-FR', 'en-NL', 'en-IT', 'en-ES', 'en-MX', 'en-DE', 'en-AT', 'en-SE', 'en-NO', 'en-DK'];
    return locales.filter(loc => !omitLocales.includes(loc)).map(locale => new Intl.Locale(locale)).flatMap(locale => {
      if (locale.region == null) return [];
      const sortKey = new Intl.DisplayNames([locale], {
        type: 'region'
      }).of(locale.region);
      let name = new Intl.DisplayNames([locale], {
        type: 'region'
      }).of(locale.region);
      const flag = getFlagEmoji(locale);
      const domainLocale = domainLocales?.find(domainLocale => domainLocale.defaultLocale === locale.toString());
      if (sortKey == null || name == null || flag == null) return [];
      const hrefProtocol = domainLocale?.http ? 'http' : 'https';
      const href = domainLocale?.domain == null ? '/' : `${hrefProtocol}://${domainLocale.domain}`;
      name = name === 'United Arab Emirates' ? 'UAE' : name;
      return [{
        locale,
        href,
        name,
        flag,
        sortKey
      }];
    }).sort((a, b) => a.sortKey.localeCompare(b.sortKey));
  }, [domainLocales, locales]);
  return <header className={clsx(className, `flex flex-col bg-white z-30 ${mobileNavOpen ? 'fixed inset-0 top-0' : ''}`)} data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
			<div className="h-9 border-b border-gray-1200 bg-gray-1400">
				<nav className="mx-auto flex h-9 max-w-[1156px] justify-between px-0 md:px-8 lg:px-4">
					<ul className="flex h-full w-full text-eyebrow font-medium uppercase md:w-80">
						{navigationTabs?.map(({
            text,
            link,
            active
          }, i) => {
            if (!text || !link) return null;
            return <li className={clsx('box-content block h-full w-1/2 border-b', active ? 'border-white bg-white' : 'border-transparent bg-transparent')} key={i}>
									<Link {...link} className={clsx('block h-full text-center leading-9', active ? 'text-black-100' : 'border-x border-transparent text-gray-700 hover:border-gray-1200 hover:bg-gray-1300 hover:text-gray-400')}>
										{text}
									</Link>
								</li>;
          })}
					</ul>

					<ul className="hidden h-full font-medium md:flex">
						<li className="group relative flex h-full cursor-pointer items-center border-x border-transparent px-3 text-gray-700 after:h-1 after:w-2 after:border-4 after:border-b-0 after:border-x-transparent after:border-t-gray-900 hover:border-gray-1200 hover:bg-gray-1300 hover:text-gray-400">
							{currentLocale && <span className="text-lg mr-2">{getFlagEmoji(new Intl.Locale(currentLocale))}</span>}
							<ul className="absolute top-full left-0 z-10 hidden h-72 w-44 overflow-auto bg-white shadow-lg group-hover:block">
								{countries?.map((country, i) => {
                return <li key={i} className="h-8 font-normal text-[13px] text-gray-700">
											<Link href={country.href} locale={country.locale.toString()} className="flex items-center bg-transparent py-2 px-3 transition-colors hover:bg-gray-1300">
												<span className="mr-1 text-[18px] h-[18px]">{country.flag}</span>
												{country.name}
											</Link>
										</li>;
              })}
							</ul>
						</li>

						{topNavLinks?.map((topNavLink, i) => {
            return <li key={i} className="h-full px-3">
									<Link href={topNavLink.link?.href ?? '#'} onClick={topNavLink.link?.onClick} target={topNavLink.link?.target} className="block h-full text-eyebrow leading-9 text-gray-700 hover:text-gray-400">
										{topNavLink.text}
									</Link>
								</li>;
          })}
					</ul>
				</nav>
			</div>

			<div ref={stickyRef} className={clsx(`flex flex-1 flex-col bg-white ${sticky ? 'fixed inset-x-0 top-0 z-10 shadow-lg' : ''} ${mobileNavOpen ? 'min-h-0' : ''} ${sticky && mobileNavOpen ? 'h-full' : ''}`)}>
				<nav className="mx-auto flex h-24 w-full max-w-[1156px] justify-between px-8 lg:px-4 items-center">
					<div className="min-w-14 max-w-[190px] flex-1 md:min-w-[156px] md:h-10">
						<Link href={logoLink?.href ?? '#'} onClick={logoLink?.onClick} target={logoLink?.target} data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">
							{logoImage?.url && <Image loading="eager" src={logoImage.url} alt={logoAlt} width={logoWidth} height={logoWidth / (logoImage?.dimensions?.width / logoImage?.dimensions?.height)} className="hidden md:block" onError={onImageError} />}
							{mobileLogoImage?.url && <Image loading="eager" src={mobileLogoImage.url} alt={logoAlt} width={mobileLogoWidth} height={mobileLogoWidth / (mobileLogoImage?.dimensions?.width / mobileLogoImage?.dimensions?.height)} className="block md:hidden h-8" onError={onImageError} />}
						</Link>
					</div>

					<ul className="hidden h-full flex-1 text-[13px] font-medium md:flex">
						{mainNavLinks?.map((mainNavLink, i) => <li key={i} className="group relative">
								<Link href={mainNavLink.link?.href ?? '#'} onClick={mainNavLink.link?.onClick} target={mainNavLink.link?.target} className="flex h-full flex-col justify-center px-4 text-gray-700 group-hover:bg-gray-1400">
									{mainNavLink.text}
								</Link>

								{mainNavLink.subnavGroups?.length > 0 && <div className="absolute bottom-3 left-1/2 hidden h-[5px] w-[10px] -translate-x-1/2 border-[5px] border-b-0 border-x-transparent border-t-gray-900 group-hover:block"></div>}

								{mainNavLink.subnavGroups?.length > 0 && <div className="absolute top-full min-w-max left-0 z-10 hidden border-l-[6px] border-bc-blue bg-white px-10 py-8 shadow-lg group-hover:block">
										<div className="flex gap-9">
											{mainNavLink.subnavGroups?.map((subnavGroup, i) => <ul key={i} className="shrink-0 min-w-[140px]">
													<li className="uppercase text-gray-600 text-eyebrow mb-1 font-normal">
														{subnavGroup.heading}
													</li>
													{subnavGroup.subnavLinks?.map((subnavLink, i) => <li key={i} className="pt-2 pb-1">
															<Link href={subnavLink.link?.href ?? '#'} onClick={subnavLink.link?.onClick} target={subnavLink.link?.target} className="group/link block text-gray-400">
																<span className="text-[13px] leading-[27px] pb-.5 border-b-2 border-transparent group-hover/link:border-gray-900">
																	{subnavLink.text}
																</span>

																{subnavLink.subtext && <div className="text-xxs font-normal leading-snug">
																		{subnavLink.subtext}
																	</div>}
															</Link>
														</li>)}
												</ul>)}
										</div>
									</div>}
							</li>)}
					</ul>

					<div className="flex items-center gap-6">
						{ctaText && <div>
								<Button variant="filled" color="primary" size="small" link={ctaLink} showIcon={false} className="md:hidden">
									{ctaText}
								</Button>

								<Button variant="filled" color="primary" size="default" link={ctaLink} showIcon={false} className="hidden md:block">
									{ctaText}
								</Button>
							</div>}

						<button onClick={() => setMobileNavOpen(!mobileNavOpen)} className="block w-7 md:hidden">
							<span className="sr-only">{mobileNavOpen ? 'close menu' : 'open menu'}</span>
							{mobileNavOpen ? <svg height="18" width="19" className="mx-auto fill-gray-700">
									<title>Close Nav Icon</title>
									<g fillRule="evenodd">
										<path d="M.66116524 16.4246212L16.9246212.16116524l1.41421356 1.41421356L2.0753788 17.83883476z"></path>
										<path d="M18.33883476 16.4246212L2.0753788.16116524.66116524 1.5753788 16.9246212 17.83883476z"></path>
									</g>
								</svg> : <svg height="18" width="28" className="fill-gray-700">
									<g fillRule="evenodd">
										<path d="M0 0h28v2H0zM0 8h28v2H0zM0 16h28v2H0z"></path>
									</g>
								</svg>}
						</button>
					</div>
				</nav>

				<nav className={`flex-1 flex-col bg-white overflow-auto ${mobileNavOpen ? 'flex' : 'hidden'}`}>
					<Accordion.Root type="multiple" className="flex-1" data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx">
						{mainNavLinks?.map((mainNavLink, i) => <Accordion.Item value={'item' + i} key={i}>
								<Accordion.Trigger className="group w-full text-left">
									<Link href={mainNavLink.link?.href ?? '#'} onClick={mainNavLink.link?.onClick} target={mainNavLink.link?.target} className="relative flex items-center border-t border-gray-900 py-5 px-8 text-[13px] text-gray-700">
										<span className="flex-1">{mainNavLink.text}</span>

										{mainNavLink.subnavGroups?.length > 0 && <div className="w-3 border-[6px] border-b-0 border-x-transparent border-t-gray-900 transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>}
									</Link>
								</Accordion.Trigger>

								{mainNavLink.subnavGroups?.length > 0 && <Accordion.AccordionContent className="px-8 pt-5 pb-8 space-y-8">
										{mainNavLink.subnavGroups?.map((subnavGroup, i) => <ul key={i} className="space-y-3">
												{subnavGroup.heading.length > 0 && <li className="text-gray-600 text-eyebrow">{subnavGroup.heading}</li>}

												{subnavGroup.subnavLinks?.map((subnavLink, i) => <li key={i}>
														<Link href={subnavLink.link?.href ?? '#'} onClick={subnavLink.link?.onClick} target={subnavLink.link?.target} className="text-xs text-gray-600">
															{subnavLink.text}

															{subnavLink.subtext && <div className="text-xxs font-normal">{subnavLink.subtext}</div>}
														</Link>
													</li>)}
											</ul>)}
									</Accordion.AccordionContent>}
							</Accordion.Item>)}
					</Accordion.Root>

					<div className="border-t border-gray-900 py-5 px-8 text-gray-700 text-[13px] relative flex gap-4 flex-col">
						{topNavLinks?.map((topNavLink, i) => <Link key={`${topNavLink.text}-${i}`} href={topNavLink?.link?.href ?? ''}>
								{topNavLink.text}
							</Link>)}
					</div>
					<Accordion.Root type="multiple" data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx">
						<Accordion.Item value={'countries'} data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx">
							<Accordion.Trigger className="group w-full text-left" asChild={true} data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx">
								<button className="w-full flex items-center border-t border-gray-900 py-5 px-8 text-[13px] text-gray-700">
									<span className="flex-1">
										{currentLocale && <span className="text-sm mr-4">
												{getFlagEmoji(new Intl.Locale(currentLocale))}
											</span>}
										Change country
									</span>

									<div className="w-3 border-[6px] border-b-0 border-x-transparent border-t-gray-900 transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>
								</button>
							</Accordion.Trigger>
							<Accordion.AccordionContent className="px-8 pt-5 pb-8 space-y-8" data-sentry-element="unknown" data-sentry-source-file="Navigation.tsx">
								<ul className="space-y-3">
									{countries?.map((country, i) => {
                  return <li key={i} className="h-8 font-normal text-gray-700">
												<Link href={country.href} locale={country.locale.toString()} className="flex items-center text-sm text-gray-600">
													<span className="mr-4 text-md h-5">{country.flag}</span>
													{country.name}
												</Link>
											</li>;
                })}
								</ul>
							</Accordion.AccordionContent>
						</Accordion.Item>
					</Accordion.Root>
				</nav>
			</div>

			<div style={{
      height: sticky ? `${stickyRef.current?.clientHeight}px` : '0px'
    }} />
		</header>;
}